
import { lazy } from 'react';
import PublishUtils from '../../../../helpers/PublishUtils';
//import {PublishUtils} from PublishUtils;


export default class BBDFormDescriptor  {
 
  static getDescription() {
    return {
      name: 'BBDForm',
      component: lazy(() =>  import('./BBDForm')),
      label: 'BBDForm',
      variables: [
        {
          name: 'title',
          type: 'text',
          label: 'Titel boven de form',
        },
        {
          name: 'classOrUri',
          type: 'text',
          label: 'uri van de owl/shacl class or individual',
        },
        {
          name: 'addEditToolbar',
          type: 'boolean',
          label: 'add edit toolbar (not implemented yet)',
        },
        {
          name: 'query',
          type: 'yasgui',
          label:
            'to fill the form. Use graph,title,uri, propUri, label,value, type (xsd,select,relation,stringlist), optionLabel optionValue ?required regex, multiple,objectOfCls, disabled,hidden. createClass for create button ',
        },
        {
          name: 'cancelbutton',
          type: 'boolean',
          label: ' show  cancel button.',
        },
        
        {
          name: 'readonly',
          type: 'boolean',
          label: 'ready only, no buttons',
        },
        {
          name: 'masseditmode',
          type: 'boolean',
          label: "use multiple uri's but only show prop distinctly. no values for now "
        },
       
        {
          name: 'disabled',
          type: 'boolean',
          label: 'disabled',
        },
        {
          name: 'emptyText',
          type: 'text',
          label: 'text by empty sparql results',
        },
        {
          name: 'hyperlinkPar',
          type: 'text',
          label: 'parameter to update when selecting a hyperlink',
        },

        
        {
          name: 'submit',
          type: 'select',
          label: 'submit handling ',
          options: [
            { label: 'kies', value: 'kies' },
            { label: 'form command', value: 'formCommand' },
            { label: 'internal parameters', value: 'internalparameters' },
            { label: 'javascript', value: 'javascript' },
            
          ],
        },
        {
          name: 'ruleTagAfterSubmit',
          type: 'text',
          label: 'run rule with tag after succesful submit',
        },
        {
          name: 'parameters',
          type: 'text',
          label:
            'extra parameters send in JSON format,Parameters will be resolved before sending. keep it simple. no subchildren',
        },
        
        PublishUtils.getTimestampParametersDef(),
        {
          name: 'failureMessage',
          type: 'text',
          label: 'failure message',
        },
        {
          name: 'closePopup',
          type: 'boolean',
          label: 'close popup after succesful submit',
        },
      ],
    }
  }

  
}
