import './bbutil'
import BBIFrame from './BBIFrame'
import PublishUtils from '../../../helpers/PublishUtils'
import AfterJS from '../../../helpers/AfterJS'

class BBMap extends BBIFrame {
  getUrl = ()  => `/${window.bbFolderName}/map/leafletWidgetDeploy171V1.html`

  constructor() {
    super()
    //console.log("BBMap Constructor");
    this.scrolling = 'no'
    this.noEvents = false;
    this.usesParameters=false;
  }

  static getDescription() {
    return {
      name: 'BBMap',
      component: BBMap,
      label: 'Leaflet map',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          defaultQuery:"PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n\r\n\rselect distinct ?uri ?color ?geometry (concat(\"label: \",STR(?color)) as ?label)\n\r\n\rwhere\n\r{\n\rgraph <{{graph}}>\n\r    {\n\r      ?uri <http://www.opengis.net/ont/geosparql#asWKT> ?geometry.\n\r    ?uri a ?cls.\n\r       \n\r    }\n\r  ?cls rdfs:label ?color\n\r \n\r    }\n\rlimit 1000\n\r    \n\r#title: title;\n\r \n\r",
          label:
            'to fill the map. Use ?uri and ?geometry for WKT strings. Optionally use ?color for automatic coloring. hints for coloring #color:RGBA(0,255,0,1)@"values"infottext&extr text       #baselayer:PDOK grijs; #title:titel;',
        },
        {
          name: 'basequery',
          type: 'yasgui',
          label:
            'default for creating a map. when no parameters are available ',
        },
        {
          name: 'height',
          type: 'text',
          label: 'height variable',
        },
        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which an item URI is published, when a feature in the map is clicked.',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label:
            'Published uri variabele on which to listen to define selection.',
        },

        AfterJS.getJSScriptAfterSelectionChange(),
        PublishUtils.getResetValueDef(),
        {
          name: 'className',
          type: 'text',
          label: 'iframe classNames',
        },
      ],
    }
  }

  state = { selectedUri: null }
}
export default BBMap
